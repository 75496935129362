import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { navigate } from 'gatsby';
import { FormStyles } from '../../styles/Components/Forms/ConversionForm';
import { FormSpinner } from '../../utils/Spinner';
import setValuesFromParams from './utils/setValuesFromParams';

const FormNew = props => {
  const { formId, title, subtitle, sfcid, variant } = props;
  const handleBeforeSubmit = (form, data) => {
    const findValue = data.find(item => item.name === 'contact_me_via_fax');
    if (!!findValue.value) {
      navigate('/thankyou');
    }
  };
  return (
    <FormStyles variant={variant}>
      <h4 className="form-title">{title}</h4>
      <p className="form-subtitle">{subtitle}</p>
      <div id="hub-form">
        <HubspotForm
          formId={formId}
          loading={<FormSpinner />}
          onFormReady={setValuesFromParams}
          onBeforeFormSubmit={handleBeforeSubmit}
          portalId="5424293"
          sfdcCampaignId={sfcid}
        />
      </div>
    </FormStyles>
  );
};

export default FormNew;
