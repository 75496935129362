import React from 'react';
import ReviewsRatings from '../../../molecules/ReviewRatings/ReviewsRatings';
import ConversionForm from '../../Forms/ConversionForm';
import {
  Content,
  ContentWrapper,
  Heading,
  Kicker,
  ReferenceWrapper,
  Subhead,
} from '../ConversionPanelStyles';

const ConversionFormComponent = ({
  formId,
  heading,
  headingKicker,
  sfcid,
  size,
  subhead,
  subtitle,
  theme,
  title,
  variant,
  ...rest
}) => {
  return (
    <ContentWrapper>
      {variant !== 'Form-Only' && (
        <Content align="left">
          {headingKicker && <Kicker theme={theme}>{headingKicker}</Kicker>}
          {heading && (
            <Heading theme={theme} size={size} variant={variant}>
              {heading}
            </Heading>
          )}
          {subhead && (
            <Subhead
              variant={'V2'}
              dangerouslySetInnerHTML={{ __html: subhead }}
              theme={theme}
            />
          )}
          <ReviewsRatings
            isDark={theme === 'Dark'}
            variant={variant}
            {...rest}
          />
        </Content>
      )}
      <ReferenceWrapper>
        <ConversionForm
          formId={formId}
          title={title}
          subtitle={subtitle}
          sfcid={sfcid}
          variant={variant}
        />
      </ReferenceWrapper>
    </ContentWrapper>
  );
};

export default ConversionFormComponent;
